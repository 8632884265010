import '@fancyapps/ui/dist/fancybox/fancybox.css';
import '@fancyapps/ui/dist/carousel/carousel.css';
import '../css/load.scss';

import List from 'list.js';
import { Fancybox } from '@fancyapps/ui';
import { Carousel } from '@fancyapps/ui/dist/carousel/carousel.esm.js';

(function ($) {
	const $win = $(window);
	const $doc = $(document);

	initHeaderMenuToggler();
	initLanguageSwitcher();
	initFancyBoxAlbum('album');
	initFancySlider('slider-testimonials');
	initChipLogic();
	initListProducts('list-products');

	function addOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').addClass('no-scroll');
		$overlay.addClass('active');
	}

	function removeOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').removeClass('no-scroll');
		$overlay.removeClass('active');
	}

	function scrollToSection() {
		$('a.btn-scroller').on('click', function (e) {
			let sectionId = $(this).attr('href');
			if (!sectionId.startsWith('#')) {
				return;
			}

			e.preventDefault();

			$('html, body').animate(
				{
					scrollTop: $(sectionId).offset().top - 40
				},
				1000
			);
		});
	}

	function initHeaderMenuToggler() {
		if (!$('.header__menu-toggle').length) {
			return;
		}

		const $header = $('.header__container');

		$('.header .header__menu-toggle').on('click', function (e) {
			$(this).closest('.header__container').toggleClass('menu-open');
			addOverlay('.app-loader-overlay');
			e.preventDefault();
		});

		$('.header__menu-close svg').on('click', function (e) {
			$('.header__container').toggleClass('menu-open');
			e.preventDefault();
			removeOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($header.hasClass('menu-open')) {
				if (!$header.is(e.target) && $header.has(e.target).length === 0) {
					$header.removeClass('menu-open');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function initLanguageSwitcher() {
		if (!$('#lang-switcher-btn').length) {
			return;
		}

		const $langSwitcher = $('#lang-switcher');

		$('#lang-switcher-btn').on('click', function (e) {
			e.preventDefault();

			$langSwitcher.toggleClass('active');
			$('.app-loader-overlay').css('z-index', '999997');
			addOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($langSwitcher.hasClass('active')) {
				if (!$langSwitcher.is(e.target) && $langSwitcher.has(e.target).length === 0) {
					$langSwitcher.removeClass('active');
					$('.app-loader-overlay').css('z-index', '101');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function initFancyBoxAlbum(dataSelector) {
		const options = {
			Thumbs: {
				type: 'classic'
			}
		};

		Fancybox.bind(`[data-fancybox="${dataSelector}"]`, options);
	}

	function initFancySlider(idSelector) {
		if (!document.getElementById(idSelector)) {
			return;
		}

		const container = document.getElementById(idSelector);
		const options = {
			Dots: false,
			slidesPerPage: 3
		};

		new Carousel(container, options);
	}

	function initChipLogic() {
		if (!$('.form-products').length) {
			return;
		}

		$('input[name="field-product[]"]').on('change', function () {
			if ($(this).val() !== 'All') {
				$('.input--chips input[value="All"]').prop('checked', false);
			} else {
				$('.input--chips').find('input[value!="All"]').prop('checked', false);
			}
		});
	}

	function initListProducts(elementSelector) {
		if (!$('.form-products').length) {
			return;
		}

		let options = {
			valueNames: [
				{ attr: 'data-ljs-product', name: 'ljs-product' }
			],
			listClass: 'ljs-container'
		};

		let list = new List(elementSelector, options);

		$('input[name="field-product[]"]').on('change', function () {
			let filterVal = $(this).val();
			let productValsArray = $('input[name="field-product[]"]:checked')
				.map(function (_, el) {
					return $(el).val();
				})
				.get();

			if (filterVal === 'All') {
				list.filter();
			} else {
				list.filter(function (item) {
					let allCategoriesString = item.values()['ljs-product'];
					let allCategoriesArray = allCategoriesString.split(',');

					return productValsArray.some(i => allCategoriesArray.includes(i))
				});
			}
		});
	}
})(jQuery);
